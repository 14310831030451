import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import L from 'leaflet';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom'

// Fix leaflet's default icon issue
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

// Coordinates for the addresses
const locations = [
  {
    position: [25.276987, 55.296249], // Coordinates for Regal Trade Square Office
    popupText: 'Regal Trade Square Office 8th Floor #817',
  },
  {
    position: [25.272354, 55.299354], // Coordinates for Shop Ground Floor G59a Near Skin hospital
    popupText: 'Shop Ground Floor G59a Near Skin hospital',
  },
];

const Contact = () => {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-50 to-blue-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl w-full space-y-8 bg-white p-10 rounded-3xl shadow-2xl">
        <div className="text-center">
          <h2 className="text-4xl font-extrabold text-blue-900">Send us an email</h2>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Contact Form */}
          <div className="p-6 bg-blue-50 rounded-2xl shadow-lg transform hover:scale-105 transition-transform duration-300">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <form className="space-y-6">
                <div className="space-y-4">
                  <div className="flex items-center space-x-3">
                    {/* <FaMapMarkerAlt className="text-blue-600 text-xl" /> */}
                    <input
                      id="name"
                      name="name"
                      type="text"
                      autoComplete="name"
                      required
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                      placeholder="Your Name"
                    />
                  </div>
                  <div className="flex items-center space-x-3">
                    <FaMapMarkerAlt className="text-blue-600 text-xl" />
                    <input
                      id="Address"
                      name="Address"
                      type="text"
                      autoComplete="name"
                      required
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                      placeholder="Your Address"
                    />
                  </div>
                  <div className="flex items-center space-x-3">
                    <FaEnvelope className="text-blue-600 text-xl" />
                    <input
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="flex items-center space-x-3">
                    <FaPhoneAlt className="text-blue-600 text-xl" />
                    <input
                      id="phone"
                      name="phone"
                      type="tel"
                      autoComplete="tel"
                      required
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                      placeholder="Phone Number"
                    />
                  </div>
                </div>
                <div>
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    placeholder="Your Message"
                    required
                  ></textarea>
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-3 px-6 border border-transparent text-lg font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transform hover:-translate-y-1 transition-transform duration-300"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </motion.div>
          </div>

          {/* Interactive Map using React Leaflet */}
          <div className="relative h-96 lg:h-auto overflow-hidden rounded-2xl shadow-lg transform hover:scale-105 transition-transform duration-300">
            <MapContainer
              center={[25.276987, 55.296249]} // Set initial map view
              zoom={15}
              scrollWheelZoom={false}
              className="h-full w-full rounded-2xl"
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {locations.map((location, index) => (
                <Marker key={index} position={location.position}>
                  <Popup>{location.popupText}</Popup>
                </Marker>
              ))}
            </MapContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

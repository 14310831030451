const productCategory = [
    { id : 1, label : "Hp", value : "Hp"},
    { id : 2, label : "Dell", value : "Dell"},
    { id : 3, label : "Lenovo", value : "Lenovo"},
    { id : 4, label : "Acer", value : "Acer"},
    { id : 5, label : "Asus", value : "Asus"},
    { id : 6, label : "Fujitsu", value : "Fujitsu"},
    { id : 7, label : "Toshiba", value : "Toshiba"},
    { id : 8, label : "ChromeBook", value : "ChromeBook"},

]

export default productCategory
import React from 'react';

const WhyChooseUsBanner = () => {
  return (
    <section
      className="relative bg-cover bg-center bg-no-repeat h-[500px] flex items-center justify-center"
      style={{
        backgroundImage: 'url("https://img.freepik.com/premium-photo/modern-workspace-with-laptop-lamp-desk-dark-room-3d-rendering_670147-113416.jpg?w=1060")'
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      
      {/* Text Container */}
      <div className="relative z-10 text-center px-4">
        <h2 className="text-4xl md:text-5xl font-bold text-white mb-4">
          Why Choose Us?
        </h2>
        <p className="text-lg md:text-xl text-gray-200 max-w-2xl mx-auto">
          We provide top-notch services that help your business reach its full potential. Our dedicated team is here to support you every step of the way.
        </p>
      </div>
    </section>
  );
};

export default WhyChooseUsBanner;

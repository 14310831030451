import React, { useContext, useEffect, useState } from "react";
import SummaryApi from "../common";
import Context from "../context";
import displayINRCurrency from "../helpers/displayCurrency";
import { MdDelete, MdClose } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { MdPerson, MdPhone, MdLocationCity, MdHome, MdPostAdd } from "react-icons/md"; // Import necessary icons

const Cart = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    city: "",
    address: "",
    postalCode: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const context = useContext(Context);
  const loadingCart = new Array(4).fill(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const fetchData = async () => {
    const response = await fetch(SummaryApi.addToCartProductView.url, {
      method: SummaryApi.addToCartProductView.method,
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
    });
    const responseData = await response.json();
    if (responseData.success) {
      setData(responseData.data);
    }
  };

  const handleLoading = async () => {
    await fetchData();
  };

  useEffect(() => {
    setLoading(true);
    handleLoading();
    setLoading(false);
  }, []);

  const increaseQty = async (id, qty) => {
    const response = await fetch(SummaryApi.updateCartProduct.url, {
      method: SummaryApi.updateCartProduct.method,
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        _id: id,
        quantity: qty + 1,
      }),
    });
    const responseData = await response.json();
    if (responseData.success) {
      fetchData();
    }
  };

  const decraseQty = async (id, qty) => {
    if (qty >= 2) {
      const response = await fetch(SummaryApi.updateCartProduct.url, {
        method: SummaryApi.updateCartProduct.method,
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          _id: id,
          quantity: qty - 1,
        }),
      });
      const responseData = await response.json();
      if (responseData.success) {
        fetchData();
      }
    }
  };

  const deleteCartProduct = async (id) => {
    const response = await fetch(SummaryApi.deleteCartProduct.url, {
      method: SummaryApi.deleteCartProduct.method,
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ _id: id }),
    });
    const responseData = await response.json();
    if (responseData.success) {
      fetchData();
      context.fetchUserAddToCart();
    }
  };

  const totalQty = data.reduce(
    (previousValue, currentValue) => previousValue + currentValue.quantity,
    0
  );
  const totalPrice = data.reduce(
    (preve, curr) => preve + curr.quantity * curr?.productId?.sellingPrice,
    0
  );
  const grandTotal = totalPrice 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
        const response = await fetch(SummaryApi.placeOrder.url, {
          method: SummaryApi.placeOrder.method,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: formData.fullName,
                phone: formData.phone,
                city: formData.city,
                address: formData.address,
                postalCode: formData.postalCode,
            }),
        });
        
        const responseData = await response.json();
        
        if (responseData.success) {
            setShowAlert(true);
            setModalIsOpen(false);
            // Optionally clear the cart data or refresh the cart view
            fetchData();
            // Reset form fields
            setFormData({
                fullName: "",
                phone: "",
                city: "",
                address: "",
                postalCode: "",
            });
              // Redirect to home page after 3 seconds
              const timer = setTimeout(() => {
                window.location.href = '/'; // Redirect to home
            }, 3000);

            // Cleanup the timer on unmount
            return () => clearTimeout(timer);
        } else {
            console.error(responseData.message); // Handle the error as needed
        }
    } catch (error) {
        console.error("Error placing order:", error);
    }
};

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 4000); // 2000 milliseconds = 2 seconds

      return () => clearTimeout(timer); // Clean up the timer on unmount
    }
  }, [showAlert]);

  return (
    <div className="container mx-auto">
      {showAlert && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white p-4 rounded shadow-lg z-50">
          <p className="inline">Order placed successfully!</p>
          <button
            onClick={() => setShowAlert(false)}
            className="ml-4 text-white font-bold"
          >
            X
          </button>
        </div>
      )}

      <div className="text-center text-lg my-3">
        {data.length === 0 && !loading && (
          <p className="bg-white py-5">No Data</p>
        )}
      </div>

      <div className="flex flex-col lg:flex-row gap-10 lg:justify-between p-4">
        {/* View Product */}
        <div className="w-full max-w-3xl">
          {loading
            ? loadingCart?.map((el, index) => (
                <div
                  key={el + "Add To Cart Loading" + index}
                  className="w-full bg-slate-200 h-32 my-2 border border-slate-300 animate-pulse rounded"
                ></div>
              ))
            : data.map((product) => (
                <div
                  key={product?._id + "Add To Cart Loading"}
                  className="w-full bg-white h-32 my-2 border border-slate-300 rounded grid grid-cols-[128px,1fr]"
                >
                  <div className="w-32 h-32 bg-slate-200">
                    <img
                      src={product?.productId?.productImage[0]}
                      className="w-full h-full object-scale-down mix-blend-multiply"
                    />
                  </div>
                  <div className="px-4 py-2 relative">
                    {/* Delete Product */}
                    <div
                      className="absolute right-0 text-red-600 rounded-full p-2 hover:bg-red-600 hover:text-white cursor-pointer"
                      onClick={() => deleteCartProduct(product?._id)}
                    >
                      <MdDelete />
                    </div>
                    <h2 className="text-lg lg:text-xl text-ellipsis line-clamp-1">
                      {product?.productId?.productName}
                    </h2>
                    <p className="capitalize text-slate-500">
                      {product?.productId.category}
                    </p>
                    <div className="flex items-center justify-between">
                      <p className="text-red-600 font-medium text-lg">
                        {displayINRCurrency(product?.productId?.sellingPrice)}
                      </p>
                      <p className="text-slate-600 font-semibold text-lg">
                        {displayINRCurrency(
                          product?.productId?.sellingPrice * product?.quantity
                        )}
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-1">
                      <button
                        className="border border-red-600 text-red-600 hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded"
                        onClick={() =>
                          decraseQty(product?._id, product?.quantity)
                        }
                      >
                        -
                      </button>
                      <span>{product?.quantity}</span>
                      <button
                        className="border border-red-600 text-red-600 hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded"
                        onClick={() =>
                          increaseQty(product?._id, product?.quantity)
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              ))}
        </div>

        {/* Summary */}
        <div className="mt-5 lg:mt-0 w-full max-w-sm">
          {loading ? (
            <div className="h-36 bg-slate-200 border border-slate-300 animate-pulse"></div>
          ) : (
            <div className="h-36 bg-white">
              <h2 className="text-white bg-red-600 px-4 py-1">Summary</h2>
              <div className="flex items-center justify-between px-4 gap-2 font-medium text-lg text-slate-600">
                <p>Quantity</p>
                <p>{totalQty}</p>
              </div>
              <div className="flex items-center justify-between px-4 gap-2 font-medium text-lg text-slate-600">
                <p>Total Price</p>
                <p>{displayINRCurrency(totalPrice)}</p>
              </div>
              <button
                className="bg-blue-600 p-2 text-white w-full mt-2"
                onClick={() => setModalIsOpen(true)}
              >
                Payment
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Modal for Payment Details */}
      {modalIsOpen && (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-1/3 relative">
            <button
                onClick={() => setModalIsOpen(false)}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
                <MdClose className="w-6 h-6" />
            </button>
            <h2 className="text-xl font-semibold mb-4">Payment Details</h2>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <div className="flex items-center mb-2">
                    <MdPerson className="mr-2" />
                    <input
                        type="text"
                        name="fullName"
                        placeholder="Full Name"
                        value={formData.fullName}
                        onChange={handleInputChange}
                        required
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="flex items-center mb-2">
                    <MdPhone className="mr-2" />
                    <input
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="flex items-center mb-2">
                    <MdLocationCity className="mr-2" />
                    <input
                        type="text"
                        name="city"
                        placeholder="City"
                        value={formData.city}
                        onChange={handleInputChange}
                        required
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="flex items-center mb-2">
                    <MdHome className="mr-2" />
                    <input
                        type="text"
                        name="address"
                        placeholder="Address"
                        value={formData.address}
                        onChange={handleInputChange}
                        required
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="flex items-center mb-4">
                    <MdPostAdd className="mr-2" />
                    <input
                        type="text"
                        name="postalCode"
                        placeholder="Postal Code"
                        value={formData.postalCode}
                        onChange={handleInputChange}
                        required
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <p className="mb-2">Total Quantity: {totalQty}</p>
                <p className="mb-4">
                    Total Price: {displayINRCurrency(grandTotal)}
                </p>
                <button
                    type="submit"
                    className="bg-blue-600 text-white p-2 rounded w-full"
                >
                    Place Order
                </button>
            </form>
        </div>
    </div>
)}
    </div>
  );
};

export default Cart;

import React, { useEffect, useState } from 'react'
import SummaryApi from '../common'
import { FaDollarSign, FaCreditCard, FaMoneyBillWave, FaMoneyCheck, FaFileInvoice } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const [categoryProduct,setCategoryProduct] = useState([])
  const [loading,setLoading] = useState(false)

  const categoryLoading = new Array(13).fill(null)

  const fetchCategoryProduct = async() =>{
      setLoading(true)
      const response = await fetch(SummaryApi.categoryProduct.url)
      const dataResponse = await response.json()
      setLoading(false)
      setCategoryProduct(dataResponse.data)
  }

  useEffect(()=>{
      fetchCategoryProduct()
  },[])

  const handleCategoryClick = (category) => {
    window.location.href = `/product-category?category=${category}`;
  };

  return (
    <footer className="bg-gray-800 text-gray-300 py-12">
      <div className="container mx-auto px-4 md:px-8">
        {/* Main Content */}
        <div className="flex flex-wrap justify-between">
          {/* About Section */}
          <div className="w-full md:w-1/3 mb-8 md:mb-0">
            <h2 className="text-xl font-bold text-white mb-4">About Us</h2>
            <div className="border-t-3 border-gray-600 w-10 my-1"></div>
            <p>
              {/* Fattani Computers founded
              Algmstore founded in 2015. We have been involved in the wholesale computer industry since 2019. in 1997. We have been
              <br /> involved in the wholesale
              <br /> computer industry since
              <br /> 2000. */}
              Algmstore founded in 2015. We have been <br/> involved in the  wholesale computer industry<br/> since 2019. We specialize in surplus, used and<br/> refurbished  computer products, including<br/> Personal computers, Laptops, Lcd Monitors,<br/> Tablets, Smart phone, Peripherals, etc.<br/> in large and small quantities.
            </p>
          </div>

 {/* Links Section */}
 <div className="w-full md:w-1/3 mb-8 md:mb-0">
            <h2 className="text-xl font-bold text-white mb-4">
              Get to Know Us
            </h2>
            <div className="border-t-3 border-gray-600 w-10 my-1"></div>
            <ul className="space-y-2">
            <li>
                <Link to="/" className="hover:text-white">
                  Home
                </Link>
              </li>
            <li>
                <Link to="/Reviews" className="hover:text-white">
                  Review
                </Link>
              </li>
             <li>
                <Link to="/About" className="hover:text-white">
                  About
                </Link>
              </li>
              <li>
                <Link to="/contact" className="hover:text-white">
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          {/* Brands and Newsletter Section */}
          <div className="w-full md:w-1/3 flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-4 mb-8 md:mb-0">
            {/* Brands Section */}
            <div className="w-full md:w-1/2">
              <h2 className="text-xl font-bold text-white mb-4">Brands</h2>
              <div className="border-t-3 border-gray-600 w-10 my-1"></div>
              <ul className="space-y-2">
              {loading ? (
                  categoryLoading.map((_, index) => (
                    <li key={index} className="animate-pulse">Loading...</li>
                  ))
                ) : (
                  categoryProduct.map((product) => (
                    <li key={product?.category}>
                      <Link
                        to="#"
                        onClick={() => handleCategoryClick(product?.category)}
                        className='cursor-pointer'
                      >
                        <p className="hover:text-white">{product?.category}</p>
                      </Link>
                    </li>
                  ))
                )}
              </ul>
            </div>

             {/* Newsletter Signup */}
             <div className="w-full md:w-1/2">
              <h2 className="text-xl font-bold text-white mb-4">
                Signup for Newsletter
              </h2>
              <form className="flex flex-col space-y-4">
                <div className="border-t-3 border-gray-600 w-10 my-1"></div>
                <input
                  type="email"
                  placeholder="Your Email (required)"
                  className="p-2 rounded-full text-gray-900"
                  required
                />
                <button
                  type="submit"
                  className="bg-blue-600 text-white w-full md:w-32 px-4 py-2 rounded-full text-sm hover:bg-blue-500"
                >
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-8 flex flex-col md:flex-row justify-between items-center">
          <div className="text-center text-gray-400">
            <p>&copy; 2024 Algmstore Computers. All rights reserved.</p>
          </div>
          {/* Footer Icons */}
          <div className="mt-4 md:mt-0 flex justify-center space-x-8">
            <div className="flex flex-col items-center">
              <FaDollarSign className="text-white text-2xl hover:text-yellow-400" />
              {/* <span className="text-gray-400 text-sm mt-2">Cash on Delivery</span> */}
            </div>
            <div className="flex flex-col items-center">
              <FaMoneyBillWave className="text-white text-2xl hover:text-green-400" />
              {/* <span className="text-gray-400 text-sm mt-2">Cash on Pickup</span> */}
            </div>
            <div className="flex flex-col items-center">
              <FaMoneyCheck className="text-white text-2xl hover:text-blue-400" />
              {/* <span className="text-gray-400 text-sm mt-2">Bank Transfer</span> */}
            </div>
            <div className="flex flex-col items-center">
              <FaCreditCard className="text-white text-2xl hover:text-red-400" />
              {/* <span className="text-gray-400 text-sm mt-2">Credit Card</span> */}
            </div>
            <div className="flex flex-col items-center">
              <FaFileInvoice className="text-white text-2xl hover:text-purple-400" />
              {/* <span className="text-gray-400 text-sm mt-2">Invoice</span> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

// pages/about.js
// import Head from 'next/head';
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <div
  className="w-full h-screen overflow-hidden bg-cover bg-center"
  style={{
    backgroundImage: 'url("https://img.freepik.com/free-photo/view-3d-laptop-device-with-screen-keyboard_23-2150714071.jpg?t=st=1727304763~exp=1727308363~hmac=da909699a4b83bcfafde9bdd27c6c418ffa948f5fa61b5a418008572dd50146f&w=740")',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }}
>
  <div className="w-full h-full bg-black bg-opacity-50 flex items-center justify-center flex-col">
    <h1 className="text-5xl font-bold text-white text-center">About Us</h1>
    <h1 className="text-2xl pt-6 font-bold text-white text-center">AlgmStore founded in 2015</h1>
  </div>
</div>

      <main className="bg-gray-50 min-h-screen py-10">
        <div className="container mx-auto px-4">
          {/* Company Overview */}
          <h1 className="text-4xl font-bold text-center text-gray-800 mb-6">AlgmStore</h1>
          <p className="text-center text-lg text-gray-600 mb-8">
            AlgmStore was founded in 2015. We have been involved in the wholesale computer industry since 2019. We specialize in surplus, used, and refurbished computer products, including Personal Computers, Laptops, LCD Monitors, Tablets, Smartphones, Peripherals, and more in large and small quantities.
          </p>
          <p className="text-center text-lg text-gray-600 mb-10">
            We send a large volume of equipment to many cities in Pakistan and beyond. Most of our customers are long-time repeat buyers. We also welcome local resellers who would like to buy in any quantity, from single pieces to pallet loads.
          </p>

          {/* Mission and Business Information */}
          <div className="bg-white p-6 rounded-lg shadow-md mb-10">
            <h2 className="text-2xl font-semibold text-red-800 mb-4">Our Commitment</h2>
            <p className="text-gray-700 mb-4">
              Our emphasis is on providing good-quality, tested, and working products. We stand behind everything we sell and we want our customers to be pleased with our products and service, as our success depends on your repeat business.
            </p>
            <p className="text-gray-700">
              We aim to create long-term, mutually beneficial, and friendly business relationships, whether you are a potential client or supplier.
            </p>
          </div>

          {/* Why Choose AlgmStore */}
          <div className="bg-white p-6 rounded-lg shadow-md mb-10">
            <h2 className="text-2xl font-semibold text-red-800 mb-4">Why Trust and Buy from AlgmStore?</h2>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>Many years in the used electronics business.</li>
              <li>Completely tested, working products handled by qualified expert technicians.</li>
              <li>Two branches in Karachi, located at Office 8, Floor #817, and Shop Ground Floor #G59a, Regal Trade Square, Regal Chowk Saddar.</li>
              <li>Direct import, best price guaranteed, and money-back warranty in case of hardware problems.</li>
              <li>NTN and Sales Tax registered by the Government of Sindh, Pakistan.</li>
            </ul>
          </div>

          {/* Office Locations */}
          <div className="bg-white p-6 rounded-lg shadow-md mb-10">
            <h2 className="text-2xl font-semibold text-red-800 mb-4">Our Locations</h2>
            <p className="text-gray-700">
              We have two branches in Karachi, both strategically located to serve our clients better:
            </p>
            <ul className="list-disc list-inside text-gray-700 mt-4 space-y-2">
              <li>Office 8, Floor #817, Regal Trade Square, Regal Chowk Saddar, Karachi.</li>
              <li>Shop Ground Floor #G59a, Regal Trade Square, Regal Chowk Saddar, Karachi.</li>
            </ul>
          </div>

          {/* Business Philosophy */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold text-red-800 mb-4">Our Business Philosophy</h2>
            <p className="text-gray-700 mb-4">
              At AlgmStore, we believe in creating value for our customers through quality products and reliable service. We strive to build long-lasting relationships with our clients based on trust, integrity, and mutual benefit.
            </p>
            <p className="text-gray-700">
              We are committed to providing the best possible experience for our customers and partners, ensuring that each interaction with AlgmStore is a positive one.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}

export default About;

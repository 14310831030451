import React, { useEffect, useState } from 'react';
// import image1 from '../assest/banner/banner.png';
import image2 from '../assest/banner/Untitled-1_af1fb299-a99f-4e05-94e7-8a0a1abc559d_2000x.webp';
import image3 from '../assest/banner/Gaming-Laptop-Shop-Now.jpg'
import image4 from '../assest/banner/Hp-Laptop-Shop-Now.jpg';
// import image5 from '../assest/banner/home_page_slider_2_1200x.webp';
import image5 from '../assest/banner/46721c81-1839-4f13-ae01-827ef9dad75c.jpeg';

import image6 from '../assest/banner/home_page_slider_3_2000x.webp';

import image1Mobile from '../assest/banner/71zMvWwBgQL._AC_SL1500_.jpg';
import image2Mobile from '../assest/banner/81pvEDDbZZL._AC_UF894,1000_QL80_.jpg';
import image3Mobile from '../assest/banner/e05775e673c4cf9a88bba5e08ecbb52d.jpg';
import image4Mobile from '../assest/banner/d746361e1586dfd68eb1403637a63a0e.jpg';
import image5Mobile from '../assest/banner/5718bd72a07cce61a0b9f75c689009be.jpg';

import { FaAngleRight, FaAngleLeft } from 'react-icons/fa6';

const BannerProduct = () => {
    const [currentImage, setCurrentImage] = useState(0);

    const desktopImages = [
        // image1,
        //  image2, image3, image4, 
         
         image5,
         
        //  image6
        ];
    const mobileImages = [image1Mobile, image2Mobile, image3Mobile, image4Mobile, image5Mobile];

    const nextImage = () => {
        if (desktopImages.length - 1 > currentImage) {
            setCurrentImage((prev) => prev + 1);
        }
    };

    const prevImage = () => {
        if (currentImage !== 0) {
            setCurrentImage((prev) => prev - 1);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (desktopImages.length - 1 > currentImage) {
                nextImage();
            } else {
                setCurrentImage(0);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [currentImage]);

    return (
        <div className='h-112 md:h-144 w-full rounded'>
            <div className='h-96 md:h-144 w-full bg-slate-200 relative'>
                <div className='absolute z-10 h-full w-full md:flex items-center hidden'>
                    <div className='flex justify-between w-full text-2xl'>
                        <button onClick={prevImage} className='bg-white shadow-md rounded-full p-1'>
                            <FaAngleLeft />
                        </button>
                        <button onClick={nextImage} className='bg-white shadow-md rounded-full p-1'>
                            <FaAngleRight />
                        </button>
                    </div>
                </div>

                {/** Desktop and tablet version */}
                <div className='hidden md:flex h-full w-full overflow-hidden'>
                    {desktopImages.map((imageURL, index) => (
                        <div
                            className='w-full h-full min-w-full min-h-full transition-transform'
                            key={imageURL}
                            style={{ transform: `translateX(-${currentImage * 100}%)` }}
                        >
                            <img src={imageURL} alt={`Banner ${index + 1}`} className='w-full h-full' />
                        </div>
                    ))}
                </div>

                {/** Mobile version */}
                <div className='flex h-full w-full overflow-hidden md:hidden'>
                    {mobileImages.map((imageURL, index) => (
                        <div
                            className='w-full h-full min-w-full min-h-full transition-transform'
                            key={imageURL}
                            style={{ transform: `translateX(-${currentImage * 100}%)` }}
                        >
                            <img src={imageURL} alt={`Banner Mobile ${index + 1}`} className='w-full h-full ' />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BannerProduct;

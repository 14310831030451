// src/components/UserDetail.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoMdClose } from 'react-icons/io';
import SummaryApi from '../common'; // Adjust the path if necessary
import { toast } from 'react-toastify';
import Status from '../common/Status'; // Ensure Status is imported
import '../UserDetail.css'; // Import custom CSS file for scrollbar styles

const UserDetail = ({
    name,
    email,
    phone,
    city,
    address,
    postalCode,
    userId,
    quantity,
    brandName,
    category,
    description,
    productImage,
    productName,
    sellingPrice,
    productId,
    onClose,
    callFunc,
}) => {
    const [userStatus, setUserStatus] = useState(Status.Pending);
    const [userPhone, setUserPhone] = useState(phone);
    const [userCity, setUserCity] = useState(city);
    const [userAddress, setUserAddress] = useState(address);
    const [userPostalCode, setUserPostalCode] = useState(postalCode);

    const handleOnChangeSelect = (e) => {
        setUserStatus(e.target.value);
    };

    const updateUserStatus = async () => {
        try {
            const fetchResponse = await fetch(SummaryApi.updateOrder.url, {
                method: SummaryApi.updateOrder.method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    orderId: userId,
                    status: userStatus,
                }),
            });

            const responseData = await fetchResponse.json();

            if (responseData.success) {
                toast.success(responseData.message);
                onClose();
                callFunc();
            } else {
                toast.error(responseData.message);
            }
        } catch (error) {
            toast.error('An error occurred while updating the user status.');
            console.error('Error updating user status:', error);
        }
    };

    return (
        <div className="fixed inset-0 w-full h-full z-50 flex justify-center items-center bg-slate-200 bg-opacity-75">
            <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-lg mx-4 md:max-w-xl lg:max-w-2xl max-h-[90vh] overflow-y-auto custom-scrollbar">
                <button className="block ml-auto text-xl text-gray-600 hover:text-gray-800" onClick={onClose}>
                    <IoMdClose />
                </button>

                <h1 className="pb-4 text-2xl font-semibold text-gray-800">User Detail</h1>
                <div className="space-y-2 text-gray-700">
                    <p><span className="font-medium">Full Name:</span> {name}</p>
                    <p><span className="font-medium">Email:</span> {email}</p>
                    <p><span className="font-medium">Phone:</span> {userPhone}</p>
                    <p><span className="font-medium">City:</span> {userCity}</p>
                    <p><span className="font-medium">Address:</span> {userAddress}</p>
                    <p><span className="font-medium">Postal Code:</span> {userPostalCode}</p>
                </div>

                <h1 className="pt-6 pb-4 text-2xl font-semibold text-gray-800">Order Detail</h1>
                <div className="flex flex-col items-start">
                    <img src={productImage} alt="Product" className="object-contain h-40 w-full rounded-md border border-gray-200" />
                    <div className="mt-4 space-y-2 text-gray-700">
                        <p><span className="font-medium">Product ID:</span> {productId}</p>
                        <p><span className="font-medium">Product Name:</span> {productName}</p>
                        <p><span className="font-medium">Selling Price:</span> ${sellingPrice}</p>
                        <p><span className="font-medium">Brand Name:</span> {brandName}</p>
                        <p><span className="font-medium">Category:</span> {category}</p>
                        <p><span className="font-medium">Quantity:</span> {quantity}</p>
                        <p><span className="font-medium">Description:</span> {description}</p>
                    </div>
                </div>

                <div className="flex items-center justify-between mt-6">
                    <label htmlFor="status" className="font-medium text-gray-700">Status:</label>
                    <select
                        id="status"
                        className="border px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                        value={userStatus}
                        onChange={handleOnChangeSelect}
                    >
                        {Object.entries(Status).map(([key, value]) => (
                            <option value={value} key={key}>{value}</option>
                        ))}
                    </select>
                </div>

                <button
                    className="w-full mt-6 py-2 px-4 rounded-md bg-red-600 text-white font-medium hover:bg-red-700 transition duration-150"
                    onClick={updateUserStatus}
                >
                    Change Status
                </button>
            </div>
        </div>
    );
};

UserDetail.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.string,
    postalCode: PropTypes.string,
    userId: PropTypes.string.isRequired,
    quantity: PropTypes.number,
    brandName: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    productImage: PropTypes.string,
    productName: PropTypes.string,
    sellingPrice: PropTypes.number,
    productId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    callFunc: PropTypes.func.isRequired,
};

export default UserDetail;

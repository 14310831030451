import React, { useEffect } from 'react'
import CategoryList from '../components/CategoryList'
import BannerProduct from '../components/BannerProduct'
// import HorizontalCardProduct from '../components/HorizontalCardProduct'
import VerticalCardProduct from '../components/VerticalCardProduct'
import Banner from '../components/Banner'
import { useLocation } from 'react-router-dom'


const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <CategoryList/>
      <BannerProduct/>

      <VerticalCardProduct category={"Hp"} heading={"Hp"}/>
      <VerticalCardProduct category={"Dell"} heading={"Dell"}/>
      <VerticalCardProduct category={"ChromeBook"} heading={"ChromeBook"}/>

      <VerticalCardProduct category={"Lenovo"} heading={"Lenovo"}/>
      <VerticalCardProduct category={"Acer"} heading={"Acer"}/>
      <Banner/>
      <VerticalCardProduct category={"Asus"} heading={"Asus"}/>
      <VerticalCardProduct category={"Fujitsu"} heading={"Fujitsu"}/>
      <VerticalCardProduct category={"Toshiba"} heading={"Toshiba"}/>
    </div>
  )
}

export default Home
import React, { useEffect } from 'react'

import { MdStar } from 'react-icons/md';
import { useLocation } from 'react-router-dom'

const reviews = [
    {
        name: "cd point",
        date: "a year ago",
        content: "Best quality and very reasonable price and 100% original machine. And best warranty support 15-day warranty. I am fully satisfied, thanks AlgmStore.",
        rating: 5,
    },
    {
        name: "ameet kumar",
        date: "a year ago",
        content: "In reasonable price, 100% original best quality product. I am fully satisfied... My 3rd laptop bought from AlgmStore. Thanks for good behavior and services.",
        rating: 5,
    },
    {
        name: "Owais Farooq",
        date: "a year ago",
        content: "Good Computer Shop! I purchased a laptop with them just 2 days ago and it is perfectly working and giving me the charging timing of 4 hours. Perfectly wrapped and all the parts of the laptop are functional and I am really satisfied!",
        rating: 4,
    },
    {
        name: "Abulfazal Kothari",
        date: "2 years ago",
        content: "Laptop wholesale shop with very good options and prices. Good dealing. Check it properly before leaving the shop.",
        rating: 4,
    },
    {
        name: "Zeeshan Akhtar",
        date: "2 years ago",
        content: "Excellent customer support and good market competitive rates for laptops.",
        rating: 5,
    },
];

const Review = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const location = useLocation();
    
      useEffect(() => {
        window.scrollTo(0, 0);
      }, [location]);
    const renderStars = (rating) => {
        return [...Array(5)].map((_, index) => (
            <MdStar key={index} className={`h-5 w-5 ${index < rating ? 'text-yellow-500' : 'text-gray-300'}`} />
        ));
    };

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-4">AlgmStore Reviews</h1>
            <div className="flex items-center mb-4">
                <span className="text-xl font-semibold">Rating: 4.8</span>
                <span className="ml-2 text-gray-600">(Based on 171 reviews)</span>
            </div>
            <p className="text-gray-500 mb-4">Powered by Google</p>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {reviews.map((review, index) => (
                    <div key={index} className="border rounded-lg p-4 shadow-md bg-white transition-transform transform hover:scale-105">
                        <h2 className="text-lg font-semibold">{review.name}</h2>
                        <p className="text-gray-500">{review.date}</p>
                        <div className="flex items-center mt-1">
                            {renderStars(review.rating)}
                        </div>
                        <p className="mt-2 text-gray-700">{review.content}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Review;

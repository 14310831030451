import React, { useState } from 'react';
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";
import AdminEditProduct from './AdminEditProduct';
import displayINRCurrency from '../helpers/displayCurrency';
import SummaryApi from '../common'

const AdminProductCard = ({ data, fetchdata }) => {
  const [editProduct, setEditProduct] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState(false);
  const deleteProducts = async () => {
    try {
      const response = await fetch(`${SummaryApi.deleteProduct.url.replace(':productId', data._id)}`, {
        method: 'DELETE',
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete the product');
      }
  
      const dataResponse = await response.json();
  
      console.log("Product deleted successfully", dataResponse);
  
      // Refresh the product list after deletion
      fetchdata();
      setDeleteProduct(false); // Close the delete confirmation modal
  
    } catch (error) {
      console.error('Error deleting the product:', error);
    }
  };
  

  const handleDelete = () => {
    deleteProducts();
  };
  

  return (
    <div className="bg-white p-6 rounded-xl shadow-lg w-full max-w-xs md:max-w-sm lg:max-w-md mx-auto">
      <div className="flex flex-col items-center">
    
    {/* Product Image Section */}
        <div className="w-full h-36 flex justify-center items-center mb-4">
          <img
            src={data?.productImage[0]}
            className="mx-auto object-contain h-full max-w-full rounded-lg shadow-md"
            alt={data?.productName}
          />
        </div>
    {/* Product Name */}
        <h1 className="text-ellipsis line-clamp-2 font-medium text-sm md:text-base mt-2 text-center text-gray-800">
          {data.productName}
        </h1>

    {/* Availability Status */}
    <h2
      className={`mt-1 text-center font-semibold ${
        data.available ? 'text-green-500' : 'text-red-500'
      }`}
    >
      {data.available ? "Available" : "Sold"}
    </h2>

    {/* Price Section */}
    <div className="mt-4 text-center">
      <p className="font-semibold text-lg md:text-xl text-gray-800">
        {displayINRCurrency(data.sellingPrice)}
      </p>
    </div>

    {/* Action Buttons */}
    <div className="flex justify-center mt-4 space-x-4">
      <div
        className="w-fit p-3 bg-green-100 hover:bg-green-600 rounded-full hover:text-white cursor-pointer transition-all"
        onClick={() => setEditProduct(true)}
      >
        <MdModeEditOutline className="text-lg md:text-xl" />
      </div>
      <div
        className="w-fit p-3 bg-red-100 hover:bg-red-600 rounded-full hover:text-white cursor-pointer transition-all"
        onClick={() => setDeleteProduct(true)}
      >
        <MdDeleteOutline className="text-lg md:text-xl" />
      </div>
    </div>
  </div>

  {/* Edit Product Modal */}
  {editProduct && (
    <AdminEditProduct
      productData={data}
      onClose={() => setEditProduct(false)}
      fetchdata={fetchdata}
    />
  )}

  {/* Delete Confirmation Modal */}
  {deleteProduct && (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-xl shadow-lg w-80 md:w-96">
        <h2 className="text-lg font-semibold text-gray-800">Confirm Deletion</h2>
        <p className="text-gray-600 mt-2">Are you sure you want to delete this product?</p>
        <div className="flex justify-between mt-6">
          <button
            className="p-3 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-all"
            onClick={handleDelete}
          >
            Delete
          </button>
          <button
            className="p-3 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 transition-all"
            onClick={() => setDeleteProduct(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )}
</div>
  );
}

export default AdminProductCard;

import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../assest/MainLogo.jpeg'; // Replace with the correct path to your logo image
import About from './../pages/About';

const Navbar = () => {
  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <div className=''>
          <Link to="/">
            {/* <img src={logo} alt="Logo" className="h-10 w-auto" /> Adjust height and width as needed */}
            {/* <h1 className='text-white text-lg hover:text-indigo-300 font-bold tracking-wide font-sans'>AlGM Store</h1> */}
            <h1 className='text-white text-3xl  font-extrabold tracking-wide font-sans leading-tight'>
  <span className='text-yellow-500 hover:text-orange-500'>AL</span><span className='text-orange-500 hover:text-yellow-500'>GM</span> <span className='text-yellow-500 hover:text-orange-500'>Store</span>
</h1>


            {/* <h1 className='text-white text-lg hover:text-indigo-300 font-bold font-sans tracking-wide'>AlGM Store</h1> */}
          </Link>
        </div>

        {/* Navigation Links */}
        <div className="hidden md:flex space-x-8 items-center">
          <Link to="/">
            <span className="text-white text-lg hover:text-indigo-300">Home</span>
          </Link>
          <Link to="/Reviews">
            <span className="text-white text-lg hover:text-indigo-300">Reviews</span>
          </Link>
          <Link to="/About">
            <span className="text-white text-lg hover:text-indigo-300">About</span>
          </Link>
          <Link to="/contact">
            <span className="text-white text-lg hover:text-indigo-300">Contact</span>
          </Link>
        </div>

        {/* Social Icons */}
        <div className="flex space-x-4 text-white text-xl">
          <a href="https://www.facebook.com/Algmstotre.pk" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
            <FaFacebookF />
          </a>
          <a href="mailto:abdulrehman_6435@yahoo.com" className="hover:text-red-500">
            <FaEnvelope />
          </a>
          <a href="tel:+923330386491" className="hover:text-green-500">
            <FaPhoneAlt />
          </a>
          <a href="https://www.linkedin.com/in/algm-store-6ba87a23a/" target="_blank" rel="noopener noreferrer" className="hover:text-blue-700">
            <FaLinkedinIn />
          </a>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center">
          <button
            type="button"
            className="text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => document.getElementById('mobile-menu').classList.toggle('hidden')}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div id="mobile-menu" className="md:hidden bg-indigo-700 text-white p-4 space-y-4 hidden">
        <Link to="/">
          <span className="block text-lg hover:text-indigo-300">Home</span>
        </Link>
        <Link to="/Reviews">
          <span className="block text-lg hover:text-indigo-300">Reviews</span>
        </Link>
        <Link to="/About">
          <span className="block text-lg hover:text-indigo-300">About</span>
        </Link>
        <Link to="/contact">
          <span className="block text-lg hover:text-indigo-300">Contact</span>
        </Link>
        <div className="flex space-x-4 text-xl">
          <a href="https://www.facebook.com/Algmstotre.pk" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
            <FaFacebookF />
          </a>
          <a href="mailto:abdulrehman_6435@yahoo.com" className="hover:text-red-500">
            <FaEnvelope />
          </a>
          <a href="tel:+923330386491" className="hover:text-green-500">
            <FaPhoneAlt />
          </a>
          <a href="https://www.linkedin.com/in/algm-store-6ba87a23a/" target="_blank" rel="noopener noreferrer" className="hover:text-blue-700">
            <FaLinkedinIn />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React from 'react';

const Banner = () => {
  return (
    <div
      className="relative w-full h-screen overflow-hidden bg-cover bg-center"
      style={{
        backgroundImage: 'url("https://img.freepik.com/premium-photo/laptop-with-sunset-background_1002361-31744.jpg?w=1060")',
      }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div> {/* Optional: Add a dark overlay for better text visibility */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-white text-center">
        <h6 className="mb-4">UNBEATABLE DEALS AWAIT YOU</h6>
        <h1 className="text-4xl md:text-6xl font-bold mb-4">Upgrade Your Tech</h1>
        <h1 className="text-1xl md:text-2xl font-bold mb-4">Shop Branded Laptops-Quality and Savings,All in One Place!</h1>
        <a
          href="#"
          className="px-6 py-3 text-lg font-semibold bg-orange-500 rounded-lg shadow-lg hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-300 transition-transform transform hover:-translate-y-1"
        >
          Shop Now
        </a>
      </div>
    </div>
  );
};

export default Banner;

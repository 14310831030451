// src/components/Orders.js
import React, { useEffect, useState } from 'react';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import moment from 'moment';
import { MdModeEdit } from "react-icons/md";
import UserDetail from '../components/userdetail';
import { useLocation } from 'react-router-dom';
import Status from '../common/Status'; // Import the Status object

const Orders = () => {
    const [allUser, setAllUsers] = useState([]);
    const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
    const [updateUserDetails, setUpdateUserDetails] = useState({
        email: "",
        image: "",
        name: "",
        Status: Status.Pending, // Set a default value if necessary
        phone: "",
        city: "",
        address: "",
        postalCode: "",
        _id: "",
        quantity:"",
        brandName:"",
        category:"",
        description:"",
        productImage:"",
        productImage:"",
        productName:"",
        sellingPrice:"",
        productId:"",
    });

    const fetchAllUsers = async () => {
        try {
            const fetchData = await fetch(SummaryApi.AllOrder.url, {
                method: SummaryApi.AllOrder.method,
                credentials: 'include'
            });

            const dataResponse = await fetchData.json();
            if (dataResponse.success) {
                setAllUsers(dataResponse.data);
            } else {
                toast.error(dataResponse.message);
            }
        } catch (error) {
            toast.error('Failed to fetch users.');
            console.error(error);
        }
    };

    useEffect(() => {
        fetchAllUsers();
    }, []);

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className='bg-white pb-4'>
            <table className='w-full userTable'>
                <thead>
                    <tr className='bg-black text-white'>
                        <th>Sr.</th>
                        <th>Product</th>
                        <th>Qty</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {allUser.map((el, index) => (
                        <tr key={el._id}>
                            <td>{index+1}</td>
                            <td>{el?.productId?.brandName}</td>
                            <td>{el?.quantity}</td>
                            <td>{el?.status}</td>
                            <td>{moment(el?.createdAt).format('LL')}</td>
                            <td>
                                <button
                                    className='bg-green-100 p-2 rounded-full cursor-pointer hover:bg-green-500 hover:text-white'
                                    onClick={() => {
                                        setUpdateUserDetails(el);
                                        setOpenUpdateStatus(true);
                                    }}>
                                    <MdModeEdit />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
           
            {openUpdateStatus && (
                <UserDetail 
                    onClose={() => setOpenUpdateStatus(false)} 
                    image={updateUserDetails?.image}
                    name={updateUserDetails?.userId?.name}
                    email={updateUserDetails?.userId?.email}
                    Status={updateUserDetails?.Status} // Pass the status value
                    phone={updateUserDetails?.phone}
                    city={updateUserDetails?.city}
                    address={updateUserDetails?.address}
                    postalCode={updateUserDetails?.postalCode}
                    userId={updateUserDetails?._id}
                    quantity={updateUserDetails?.quantity}
                    brandName={updateUserDetails?.productId?.brandName}
                    category={updateUserDetails?.productId?.category}
                    description={updateUserDetails?.productId?.description}
                    productImage={updateUserDetails?.productId?.productImage?.[0]}
                    productName={updateUserDetails?.productId?.productName}
                    sellingPrice={updateUserDetails?.productId?.sellingPrice}
                    productId={updateUserDetails?.productId?._id}
                    callFunc={fetchAllUsers}
                />
            )}
        </div>
    );
};

export default Orders;
